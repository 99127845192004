import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ktern-planner';

  ngOnInit() {
    this.clearLocalStorageKeys();
  }

  clearLocalStorageKeys() {
    const keysToRemove = ['b-gantt-trial-start', 'b-gantt-verify-date'];
    keysToRemove.forEach(key => {
      if (localStorage.getItem(key)) {
        localStorage.removeItem(key);
        console.log(`Removed ${key} from localStorage`);
      }
    });
  }
}
